import sentry from 'helpers/sentry'
import { assign, set } from 'lodash'
import moment from 'moment'
import 'moment-timezone'
import App from './app'
import { authStore } from './auth/index'
import { bus } from './bus'
import Clipboard from './clipboard'
import Connections from './connections'
import Data from './data'
import Files from './files'
import GLOB from './glob'
import HolistiplanStore from './holistiplan'
import Images from './images'
import Integrations from './integrations'
import Mail from './mail'
import Notes from './notes'
import Perms from './perms'
import PreciseFPStore from './precisefp'
import RiskalyzeStore from './riskalyze'
import Router from './router'
import Storage from './storage'
import Tags from './tags'
import Tasks from './tasks'
import View from './viewport'
import Workflows from './workflows'
window.moment = moment

/**
 * Convenience for adding stores
 * @param {*} path
 * @param {*} value
 */
function add(path, value) {
  if (typeof path === 'object') {
    assign(global.stores, path)
  } else {
    set(global, path, value)
  }
}

/**
 * Synchronous store creation
 */
function init() {
  add('app', new App())
  add('perms', new Perms())
  add('auth', authStore)
  add('view', new View())
  add('mail', new Mail())
  add('files', new Files())
  add('notes', new Notes())
  add('images', new Images())
  add('ext', new Integrations())
  add('connections', new Connections())
  add('data', new Data())
  add('tags', new Tags())
  add('tasks', new Tasks())
  add('workflows', new Workflows())
  add('riskalyze', new RiskalyzeStore())
  add('holistiplan', new HolistiplanStore())
  add('precisefp', new PreciseFPStore())
  add('clipboard', new Clipboard())
  return global.stores
}

/**
 * Configure all stores - async
 */
export const createStores = () => {
  GLOB()
  add('bus', bus)
  add('store', new Storage())
  add('router', new Router())
  sentry()
  init()
}
