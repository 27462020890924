import { Suspense, lazy } from 'react'
import { useSignedIn } from 'stores/auth/hook'
import { CenterLayout } from './layouts/center'
import { Loading } from './routes/async'

const Main = lazy(() =>
  import('./Main').then((module) => ({
    default: module.MainApp,
  })),
)

export const MainApp = () => {
  const { signedIn } = useSignedIn()

  if (!signedIn) {
    return (
      <CenterLayout>
        <Loading />
      </CenterLayout>
    )
  }

  return (
    <Suspense fallback={<div>Loading ...</div>}>
      <Main />
    </Suspense>
  )
}
